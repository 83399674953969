/********* JY *********/

/*硫붾돱�� �곸꽭*/
.page-title .title-wrapper .icon-06-01 {
  width: 24px;
  height: 24px;
  margin: 0px 12px 0px 0px;
  background: url(./images/icon_manual_01.png) no-repeat;
  background-size: 100%;
  display: block;
}

.content-wrapper .text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  max-width: 50px;
  padding: 11px 60px;
}

/*怨듭��ы빆*/
.page-title .title-wrapper .icon-06-02 {
  width: 24px;
  height: 24px;
  margin: 0px 12px 0px 0px;
  background: url(./images/icon_notice_01.png) no-repeat;
  background-size: 100%;
  display: block;
}

.title-wrapper .detail-btn {
  margin-left: auto;
  display: flex;
}

.title-wrapper .detail-btn .btn {
  padding: 8px 16px;
  background: #f4f5fb;
  border-radius: 16px;
  color: #111;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.content-wrapper .title-wrapper .detail-btn .icon-arrowDown {
  margin: 0 0px 0 13px;
  width: 8px;
  height: 4px;
  background: url('./images/icon_arrowDown.png') no-repeat;
  background-size: 100%;
  display: block;
}

.content-wrapper .title-wrapper .detail-btn .icon-arrowUp {
  margin: 0 0px 0 13px;
  width: 8px;
  height: 4px;
  background: url('./images/icon_arrowUp.png') no-repeat;
  background-size: 100%;
  display: none;
}

.total-search-row .total-search-select.width {
  width: calc(100% / 4 - 112px);
}

.right-btn.margin-left-btn {
  margin-left: 10px;
}

.date-select-wrapper.date-left {
  display: flex;
  justify-content: left;
  align-items: center;
}

.table-wrapper .btn-celendar-select {
  width: 16px;
  height: 16px;
  margin: 0px 8px 2px 0px;
  background: url(./images/icon_celendar_select.png) no-repeat;
  background-size: 100%;
  display: block;
  cursor: pointer;
}

.left-btn {
  margin-right: auto;
  display: flex;
}

.left-btn .btn {
  margin: 0 0 0 8px;
  padding: 8px 16px;
  background: #fff;
  border: 1px solid #d4d2f1;
  border-radius: 16px;
  color: #111;
  font-size: 15px;
  display: flex;
  align-items: center;
}

.title-wrapper .title.sub-title {
  font-size: 16px;
}

/*text-color-red*/
.title-wrapper .right-btn .btn.text-color-red {
  padding: 8px 24px;
  border: 1px solid #e1e1e1;
  color: red;
}

.table-scorll-wrapper.scorll-x {
  overflow-x: scroll;
  overflow-y: hidden;
}

.table-scorll-wrapper.scorll-x .text-bold {
  font-weight: bold;
}

.table-scorll-wrapper.scorll-x .table-active {
  background: #eaecfb;
}

.table-scorll-wrapper.scorll-x .table-list tbody tr:nth-child(3) td {
  background: rgba(225, 225, 225, 0.2);
}

.table-list .btn-close {
  width: 16px;
  height: 16px;
  background: url('./images/icon_search_infput_close.png') no-repeat;
  display: block;
  cursor: pointer;
}

.table-explanation-wrapper {
  margin-top: 25px;
}
.table-explanation-wrapper .table-explanation {
  margin: 0 0 8px 0;
}

.file-search-wrapper {
  display: flex;
  align-items: center;
  justify-content: start;
}

.file-search-wrapper .file-search-icon {
  margin: 0 12px;
  padding: 8px 0 0 0;
}

.file-search-wrapper .file-search-icon .icon-file-name {
  width: 93px;
  height: 16px;
  background: url('./images/icon_file_name.png') no-repeat;
  display: inline-block;
  cursor: pointer;
}
.file-search-wrapper .btn-close {
  padding: 0;
}

/* 泥⑤��뚯씪 �꾩씠肄�(�쇱슫��) */
.file-search-icon-wrapper .file-search-icon-btn {
  width: 100%;
}

.file-search-icon-wrapper {
  line-height: 1;
}
.file-search-icon-wrapper .file-search-icon-btn .icon-file-02 {
  width: 32px;
  height: 32px;
  background-image: url('./images/icon_file_02.png');
  background-repeat: no-repeat;
  background-position: top 50% center;
  display: inline-block;
  cursor: pointer;
  margin: 0 5px;
  border: 1px solid #e5e5e5;
  border-radius: 32px;
}
.file-search-icon-wrapper .file-search-icon-btn .icon-file-02:hover {
  background-color: #e1e1e1;
}

/********** AY **********/
.total-search-disabled {
  width: 50%;
  background: #f1f1f1;
}

.total-search-disabled.left-line {
  border-left: 1px solid #e1e1e1;
}

.right-btn {
  margin-left: auto;
}

.table-wrapper th i {
  display: block;
  width: 24px;
  height: 24px;
  padding: 6px;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 24px;
}

.table-wrapper .icon-04-01 {
  background-image: url(./images/icon_04_01.png);
  background-repeat: no-repeat;
  background-position: top 50% center;
  -webkit-background-size: 12px auto;
  background-size: 12px auto;
}

.table-wrapper .info-wrapper .right-btn .btn.primary {
  padding: 8px 26px;
  background: #645af1;
  border: none;
  border-radius: 16px;
  color: #fff;
  display: flex;
  align-items: center;
}

.table-wrapper td .right-btn {
  float: right;
}

.table-wrapper td .right-btn .btn {
  margin: 0 0 0 8px;
  padding: 8px 16px;
  background: #fff;
  border: 1px solid #d4d2f1;
  border-radius: 16px;
  color: #111;
  font-size: 14px;
}

.table-wrapper td.text-middle {
  line-height: 2.1;
}

.table-wrapper td.text-vertical-align-top {
  vertical-align: top;
}

.content-wrapper .title-wrapper .right-info {
  margin-left: auto;
}

.content-wrapper .title-wrapper .title:before {
  display: inline-block;
  content: '';
  width: 4px;
  height: 4px;
  background: #111;
  border-radius: 4px;
  margin: 5px 8px 5px 0;
}

.table-wrapper .title-wrapper .title:before {
  display: inline-block;
  content: '';
  width: 4px;
  height: 4px;
  background: #111;
  border-radius: 4px;
  margin: 5px 8px 5px 0;
}

/**** table ****/
.table-wrapper .table-list tbody .border-none {
  border-bottom: none;
}

.table-wrapper .table-list tbody.border-none td {
  border: none;
}

.table-wrapper .table-list td.text-left {
  text-align: left;
}

/**** table-section ****/
.table-scorll-wrapper.scroll-inner {
  padding: 32px;
}

.table-scorll-wrapper.scroll-inner .table-section ~ .table-section {
  margin: 48px 0 0 0;
}

.table-scorll-wrapper.scroll-inner .table-list {
  border: 1px solid #e1e1e1 !important;
}

.table-wrapper.table-inner-wrapper {
  padding: 32px;
  background: #f9f9f9;
}

.table-wrapper.table-inner-wrapper .title-wrapper .title {
  font-weight: 400;
}

.table-wrapper.table-inner-wrapper .title-wrapper .title:before {
  display: inline-block;
  content: '';
  width: 10px;
  height: 10px;
  margin: 5px 14px 2px 0;
  background: url(./images/icon_table_inner_wrapper_title_before.png) no-repeat
    left center;
  -webkit-background-size: 10px 10px;
  background-size: 10px 10px;
  border-radius: 0;
}

/**** status-board-03 ****/
.status-board-03 {
  background: #f9f9f9;
}

.status-board-03 tr:nth-of-type(2) td:first-of-type {
  text-align: center;
  font-size: 34px;
}

.status-board-03 .team-wrapper {
  float: left;
  width: calc(50% - 5px);
  padding: 20px 16px;
  background: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  font-weight: 400;
}

.status-board-03 .team-wrapper:first-of-type,
.status-board-03 .team-wrapper:nth-of-type(2) {
  margin: 0 0 20px 0;
}

.status-board-03 .team-wrapper:first-of-type,
.status-board-03 .team-wrapper:nth-of-type(3) {
  margin: 0 10px 0 0;
}

.status-board-03 .team-wrapper .team-name {
  display: inline-block;
}

.status-board-03 .team-wrapper .team-name .number {
  padding: 0 0 0 8px;
  vertical-align: sub;
  font-size: 24px;
  font-weight: 600;
}

.status-board-03 .status-wrapper {
  display: inline-block;
  margin: 0 0 0 18px;
  font-size: 12px;
}

.status-board-03 .status-wrapper ul li {
  display: inline;
  margin: 0 0 0 8px;
}

/**** graph-bar-wrapper-S ****/
.graph-bar-wrapper-S ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 11px 0;
}

.graph-bar-wrapper-S ul li {
  display: flex;
  align-items: center;
  width: 33%;
  margin: 10px 0;
}

.graph-bar-wrapper-S ul li .label {
  width: 56px;
  height: 24px;
  background: #efefef;
  color: #777;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
}

.graph-bar-wrapper-S ul li .graph-bar {
  width: 106px;
  height: 24px;
  border: 1px solid #f2f1ff;
}

.graph-bar-wrapper-S ul li .graph-bar .graph-navyBlue {
  height: 22px;
  background-image: linear-gradient(to left, #3b50ce, #4e84f1);
}

.graph-bar-wrapper-S ul li .graph-data {
  margin: 0 0 0 4px;
  color: #777;
  font-size: 12px;
  font-weight: 600;
}

/**** double-select ****/
.total-search-row .total-search-select.double-select {
  width: 17.5%;
}

/**** sorting-wrapper ****/
.sorting-wrapper.bg-color-border {
  padding: 20px 32px;
  background: #f9faff;
  border: 1px solid #e1e1e1;
}

.sorting-wrapper .btn-wrapper .btn {
  margin: 0 0 0 8px;
}

.sorting-wrapper .btn-wrapper .btn.secondary {
  background: #fff !important;
  color: #111 !important;
  border: 1px solid #e5e5e5 !important;
}

/********** popup_wrapper ***********/
.popup_wrapper {
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.popup_wrapper .popup-mask {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  width: 100vw;
  height: 100vh;
  background: rgba(000, 000, 000, 0.7);
}

/**** content ****/
.popup_wrapper .content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2001;
  width: 1478px;
  min-width: auto;
  height: auto;
  min-height: auto;
  padding: 0 !important;
  background: #fff;
  border-radius: 14px 14px 12px 12px;
}

/**** header ****/
.popup_wrapper .content .header {
  min-width: auto;
  height: auto;
  border-radius: 12px 12px 0 0;
  background: #343861;
}

.popup_wrapper .content .header .inner {
  width: 100%;
  min-width: auto;
  padding: 15px 32px 15px;
  display: flex;
}

.popup_wrapper .header .title-wrapper {
  margin: 0;
  width: 100%;
}

.popup_wrapper .header .title-wrapper .title {
  color: #fff;
}

.popup_wrapper .header .btn-wrapper {
  justify-content: flex-end;
}

.popup_wrapper .header .btn-wrapper .btn {
  width: 40px;
  height: 40px;
  background: url(./images/icon_page_title_close_white.png) no-repeat right
    center;
  background-size: 16px 16px;
  padding: 0;
}

/**** content-container ****/
.popup_wrapper .content-container {
  width: 100%;
  padding: 32px;
}

.popup_wrapper .content-wrapper {
  margin: 0 0 24px 0;
}

.popup_wrapper .content-wrapper:last-of-type {
  margin: 0;
}

/**** total-search ****/
.popup_wrapper .total-search-top {
  width: 100%;
  padding: 8px 24px 8px 12px;
  background-color: #f4f5fb;
}

.popup_wrapper .total-search-content {
  padding: 0;
}

.popup_wrapper .total-search-content .search-input-wrapper {
  border: none;
}

.popup_wrapper .total-search-content .search-input-wrapper .search-word-input {
  margin: 0 12px 0 0;
  border-radius: 4px;
}

.popup_wrapper .total-search-content .search-input-wrapper .search-icon {
  top: 50%;
  transform: translateY(-50%);
}

.popup_wrapper .total-search-content .search-input-wrapper .btn-close {
  top: 50%;
  right: 24px;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
}

/**** table ****/
.popup_wrapper .table-wrapper .table-list td .form-check {
  padding: 0 0 0 2.2rem;
}

.popup_wrapper .table-wrapper .table-list td.text-overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.popup_wrapper .bottom-btn-wrapper {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0 0 0;
  padding: 0;
}

.popup_wrapper .bottom-btn-wrapper .btn {
  width: 152px;
  height: 40px;
  margin: 0 0 0 8px;
  padding: 12px 0;
  border-radius: 40px !important;
  text-align: center;
}

.popup_wrapper .bottom-btn-wrapper .primary {
  background: #645af1;
  border: none;
  color: #fff;
}

.popup_wrapper .bottom-btn-wrapper .secondary {
  border-radius: 8px;
  border: solid 1px #d4d7f3;
  background-color: #f9f9f9;
}

/**** current-situation-wrapper ****/
.popup_wrapper .current-situation-wrapper {
  width: 100%;
  padding: 12px 24px;
  border-radius: 8px;
  border: 1px solid #f1f1f1;
  background-color: #f4f5fb;
}

.popup_wrapper .current-situation-wrapper .current-situation-list ul {
  display: flex;
  align-items: center;
}

.popup_wrapper .current-situation-wrapper .current-situation-list ul li.all {
  margin: 0 48px 0 0;
  padding: 5px 24px;
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 8px;
}

.popup_wrapper .current-situation-wrapper .current-situation-list ul li ~ li {
  margin: 0 32px 0 0;
}

.popup_wrapper .current-situation-wrapper .current-situation-list ul li span {
  font-weight: 600;
}

.popup_wrapper .current-situation-wrapper .step-00-data {
  color: #111;
}
.popup_wrapper .current-situation-wrapper .step-01-data {
  color: #ffbb46;
}
.popup_wrapper .current-situation-wrapper .step-02-data {
  color: #1cce57;
}
.popup_wrapper .current-situation-wrapper .step-03-data {
  color: #5db1ff;
}
.popup_wrapper .current-situation-wrapper .step-04-data {
  color: #536bef;
}
.popup_wrapper .current-situation-wrapper .step-05-data {
  color: #fb384b;
}

/******************** calendar ********************/

/********** calendar-header-wrapper **********/
.calendar-header-wrapper {
  margin: 0 0 24px;
  padding: 0 0 18px;
  border-bottom: 1px solid #dedede;
  display: flex;
  align-items: center;
}

/* title-wrapper  */
.calendar-header-wrapper .title-wrapper {
  margin: 0;
  width: 33.3%;
  display: flex;
  align-items: center;
}

.calendar-header-wrapper .title-wrapper .title {
  color: #111;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.calendar-header-wrapper .title-wrapper .title .icon-calendar {
  margin: 0 12px 0 0;
  width: 24px;
  height: 24px;
  background: url('./images/icon_calendar.png') no-repeat;
  background-size: 100%;
  display: block;
}

/* date-choice-wrapper  */
.calendar-header-wrapper .date-choice-wrapper {
  width: 33.3%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar-header-wrapper .date-choice-wrapper .date-choice {
  color: #111;
  font-size: 32px;
  font-weight: 600;
  display: flex;
}

.calendar-header-wrapper .date-choice-wrapper .date-choice .btn-prev {
  margin: 0 32px 0 0;
  width: 20px;
  height: 20px;
  background: url('./images/btn_prev.png') no-repeat;
  background-size: 100%;
  display: block;
  cursor: pointer;
}

.calendar-header-wrapper .date-choice-wrapper .date-choice .btn-next {
  margin: 0 0px 0 32px;
  width: 20px;
  height: 20px;
  background: url('./images/btn_next.png') no-repeat;
  background-size: 100%;
  display: block;
  cursor: pointer;
}

.calendar-header-wrapper .date-choice-wrapper .btn {
  margin: 0 0 0 20px;
  height: 34px;
  padding: 0px 16px;
  border: 1px solid #d4d2f1;
  border-radius: 34px;
  font-size: 18px;
  font-weight: 600;
  color: #33297a;
  display: flex;
  align-items: center;
}

/* btn-wrapper  */
.calendar-header-wrapper .btn-wrapper {
  width: 33.3%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.calendar-header-wrapper .btn-wrapper .form-check-wrapper {
  margin: 0 8px 0 0px;
  height: 40px;
  padding: 0px 16px 0 14px;
  border: 1px solid #d4d2f1;
  border-radius: 40px;
  display: flex;
  align-items: center;
}

.calendar-header-wrapper .btn-wrapper .tabs-wrapper {
  margin: 0 0 0 0px;
  padding: 0px 16px;
  border: 1px solid #d4d2f1;
  border-radius: 40px;
}

/* tabs-header  */
.calendar-header-wrapper .btn-wrapper .tabs-header {
  margin-bottom: 0px;
  border-bottom: none;
  background: #d1d1dd;
  border-radius: 40px;
}

.calendar-header-wrapper .btn-wrapper .tabs-body {
  width: auto;
}

.calendar-header-wrapper .btn-wrapper .nav-tabs {
  position: relative;
}

.calendar-header-wrapper .btn-wrapper .nav-tabs .nav-item {
  position: relative;
  display: flex;
  align-items: center;
}

.calendar-header-wrapper .btn-wrapper .nav-tabs .nav-link {
  height: 40px;
  color: #33297a;
  border: none;
  min-width: auto;
  font-size: 16px;
  font-weight: 450;
  padding: 0 24px;
  border-radius: 0;
  position: relative;
  overflow: hidden;
}

.calendar-header-wrapper .btn-wrapper .nav-tabs .nav-link .icon-calendar {
  margin: 0 8px 0 0px;
  width: 22px;
  height: 22px;
  background: url('./images/icon_tab_calendar.png') no-repeat;
  background-size: 100%;
  display: block;
}

.calendar-header-wrapper .btn-wrapper .nav-tabs .nav-link .icon-list {
  margin: 0 8px 0 0px;
  width: 22px;
  height: 22px;
  background: url('./images/icon_tab_list.png') no-repeat;
  background-size: 100%;
  display: block;
}

.calendar-header-wrapper .btn-wrapper .nav-tabs .nav-link:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0px;
  background: #ccc !important;
  transition: transform 0.3s;
  transform: translate3d(0, 150%, 0);
}

.calendar-header-wrapper .btn-wrapper .nav-tabs .nav-link.active {
  position: relative;
  color: #33297a !important;
  background: #fff;
  border-radius: 40px;
  border: 1px solid #ccc;
}

/********** sorting-wrapper **********/
.sorting-wrapper {
  margin: 0 0 32px;
  padding: 0 0 0px;
  display: flex;
  /* border-bottom: 1px solid #dedede; */
}

/* select-wrapper */
.sorting-wrapper .select-wrapper {
  display: flex;
  align-items: center;
}

.sorting-wrapper .select-wrapper label {
  padding: 0 8px 0 0;
  color: #111;
  font-size: 14px;
}

.sorting-wrapper .form-select-wrapper {
}

/* check-wrapper */
.sorting-wrapper .check-wrapper {
  margin-left: auto;
  display: flex;
  align-items: center;
}

/*  check*/
.sorting-wrapper .check-wrapper .inner {
  display: flex;
}

.sorting-wrapper .check-wrapper .inner ul {
  display: flex;
}

.sorting-wrapper .check-wrapper .inner li {
  padding: 0 0px 0 8px;
}

.sorting-wrapper .check-wrapper .inner li.middle-line {
  margin: 6px 4px 0 14px;
  width: 1px;
  height: 10px;
  padding: 0px 0px 0 0px;
  background: #ccc;
}

/* form-check */
.sorting-wrapper .check-wrapper .form-check {
  margin: 0 0 0px 0;
  padding-left: 0px;
  display: flex;
  align-items: center;
}

.sorting-wrapper .check-wrapper .form-check-input {
  margin: 0 8px 0 0;
}

.sorting-wrapper .check-wrapper .form-check-input:checked {
  width: 16px;
  height: 16px;
  border: none;
}

/* check */
.sorting-wrapper
  .check-wrapper
  .form-check-input.sellect-all:checked[type='checkbox'] {
  background: url('./images/icon_calendar_check_00.png') no-repeat;
}

.sorting-wrapper
  .check-wrapper
  .form-check-input.sellect-01:checked[type='checkbox'] {
  background: url('./images/icon_calendar_check_01.png') no-repeat;
}

.sorting-wrapper
  .check-wrapper
  .form-check-input.sellect-02:checked[type='checkbox'] {
  background: url('./images/icon_calendar_check_02.png') no-repeat;
}

/* radio */
.sorting-wrapper
  .check-wrapper
  .form-check-input.check-step-00:checked[type='radio'] {
  background: url('./images/icon_calendar_radio_00.png') no-repeat !important;
}

.sorting-wrapper
  .check-wrapper
  .form-check-input.check-step-01:checked[type='radio'] {
  background: url('./images/icon_calendar_radio_01.png') no-repeat !important;
}

.sorting-wrapper
  .check-wrapper
  .form-check-input.check-step-02:checked[type='radio'] {
  background: url('./images/icon_calendar_radio_02.png') no-repeat;
}

.sorting-wrapper
  .check-wrapper
  .form-check-input.check-step-03:checked[type='radio'] {
  background: url('./images/icon_calendar_radio_03.png') no-repeat;
}

.sorting-wrapper
  .check-wrapper
  .form-check-input.check-step-04:checked[type='radio'] {
  background: url('./images/icon_calendar_radio_04.png') no-repeat;
}

.sorting-wrapper
  .check-wrapper
  .form-check-input.check-step-05:checked[type='radio'] {
  background: url('./images/icon_calendar_radio_05.png') no-repeat;
}

/* form-check-label */
.sorting-wrapper .check-wrapper .form-check-label {
  color: #111 !important;
}

.sorting-wrapper .check-wrapper .form-check-label .data-step-00 {
  margin: 0 0 0 4px;
  color: #111;
  font-weight: 600;
}

.sorting-wrapper .check-wrapper .form-check-label .data-step-01 {
  margin: 0 0 0 4px;
  color: #ffbb46;
  font-weight: 600;
}

.sorting-wrapper .check-wrapper .form-check-label .data-step-02 {
  margin: 0 0 0 4px;
  color: #1cce57;
  font-weight: 600;
}

.sorting-wrapper .check-wrapper .form-check-label .data-step-03 {
  margin: 0 0 0 4px;
  color: #5db1ff;
  font-weight: 600;
}

.sorting-wrapper .check-wrapper .form-check-label .data-step-04 {
  margin: 0 0 0 4px;
  color: #536bef;
  font-weight: 600;
}

.sorting-wrapper .check-wrapper .form-check-label .data-step-05 {
  margin: 0 0 0 4px;
  color: #fb384b;
  font-weight: 600;
}

/* btn-wrapper */
.sorting-wrapper .check-wrapper .btn-wrapper {
  margin: 0 0 0 32px;
}

/* btn */
.sorting-wrapper .check-wrapper .btn-wrapper .btn {
  padding: 8px 16px;
  background: #645af1;
  border-radius: 16px;
  color: #fff;
  display: flex;
  align-items: center;
}

/* legend-wrapper */
.sorting-wrapper .legend-wrapper {
  margin-left: auto;
  display: flex;
  align-items: center;
}

/* legend */
.sorting-wrapper .legend-wrapper .legend {
  display: flex;
}

.sorting-wrapper .legend-wrapper .legend ul {
  display: flex;
}

.sorting-wrapper .legend-wrapper .legend li {
  padding: 0 0 0 10px;
  display: flex;
}

.sorting-wrapper .legend-wrapper .legend i {
  margin: 0 4px 0 0;
  width: 22px;
  height: 22px;
  background-size: 100%;
  display: block;
}

.sorting-wrapper .legend-wrapper .legend-file-01 {
  background: url('./images/icon_legend_file_01.png') no-repeat;
}

.sorting-wrapper .legend-wrapper .legend-file-02 {
  background: url('./images/icon_legend_file_02.png') no-repeat;
}

.sorting-wrapper .legend-wrapper .legend-file-03 {
  background: url('./images/icon_legend_file_03.png') no-repeat;
}

.sorting-wrapper .legend-wrapper .legend-marker-01 {
  background: url('./images/icon_legend_01.png') no-repeat;
}

.sorting-wrapper .legend-wrapper .legend-marker-02 {
  background: url('./images/icon_legend_02.png') no-repeat;
}

.sorting-wrapper .legend-wrapper .legend-marker-03 {
  background: url('./images/icon_legend_03.png') no-repeat;
}

.sorting-wrapper .legend-wrapper .legend-marker-04 {
  background: url('./images/icon_legend_04.png') no-repeat;
}

.sorting-wrapper .legend-wrapper .legend-marker-05 {
  background: url('./images/icon_legend_05.png') no-repeat;
}

/********** calendar-list-title **********/
.calendar-list-title {
  margin: 0 0 20px;
  padding: 0 0 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #c9c9c9;
}

.calendar-list-title .title-wrapper {
}

.calendar-list-title .title-wrapper .title {
  color: #111;
  font-size: 24px;
  font-weight: 400;
}

.calendar-list-title .title-wrapper .title .bold {
  padding: 0 16px 0 0;
  font-weight: 600;
}

.calendar-list-title .info-wrapper {
  margin-left: auto;
  color: #000;
}

.calendar-list-title .info-wrapper ul {
  display: flex;
}

.calendar-list-title .info-wrapper li {
  margin: 0 0 0 12px;
  padding: 0 0 0 12px;
  height: 12px;
  border-left: 1px solid #999;
  line-height: 90%;
}

.calendar-list-title .info-wrapper li:first-child {
  border: none;
}

/**** table-calendar ****/
.table-calendar {
  min-width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  text-align: center;
  table-layout: fixed;
  border: 1px solid #e1e1e1;
}

.table-calendar tr {
  border-bottom: 1px solid #e1e1e1;
}

.table-calendar td {
  height: 150px;
  padding: 14px 18px;
  border-left: 1px solid #e1e1e1;
  vertical-align: top;
}

/* active */
.table-calendar td.active {
  width: 100%;
  border: 3px solid #645af1;
  border-radius: 0;
  background: none;
}

.table-calendar td.active .day {
  margin: -5px 0 0 -10px;
  width: 28px;
  height: 28px;
  background: #645af1;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
}

.table-calendar th {
  height: 56px;
  padding: 0px 0px;
  background: #f4f5fb;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  color: #111;
  font-size: 18px;
  font-weight: 600;
}

/* day */
.calendar-content-wrapper .sun {
  color: #f83649 !important;
}

.calendar-content-wrapper .sat {
  color: #2a6dac !important;
}

.calendar-content-wrapper .not-current {
  color: #999 !important;
}

.table-calendar .btn {
  height: 32px;
  padding: 0px 20px;
  background-color: #f9f9f9;
  border: solid 1px #d4d7f3;
  border-radius: 4px;
  font-size: 14px;
  color: #111;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.table-calendar .label {
  margin: 0 14px 0 0;
}

.calendar-content-wrapper .day-grid {
  display: flex;
  align-items: center;
  position: relative;
}

.calendar-content-wrapper .day {
  display: flex;
  align-items: center;
  color: #111;
}

/* event-line */
.event-line {
  width: 195px;
  padding: 4px 16px;
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  background: #fff;
  /* ellipsis */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
  position: relative;
}

.event-line .info {
  height: 8px;
  margin: 0 0 0 10px;
  padding: 0 0px 0 10px;
  border-left: 2px solid #777;
}

.event-line .explanation {
  padding: 0 0 0 6px;
  font-weight: normal;
}

.event-line.first {
  position: absolute;
  top: 54px;
  left: -8px;
}

.event-line.second {
  position: absolute;
  top: 92px;
  left: -8px;
}

/* bg */
.event-line.bg-yellow {
  background: #fff5db;
}
.event-line.bg-blue {
  background: #e1f0ff;
}

/* step */
.event-line .bar-step-00,
.bar-step-01,
.bar-step-02,
.bar-step-03,
.bar-step-04,
.bar-step-05 {
  position: absolute;
  top: 0;
  left: 0;
  width: 8px;
  height: 40px;
}
.event-line .bar-step-00 {
  background: #999;
}
.event-line .bar-step-01 {
  background: #ffd738;
}
.event-line .bar-step-02 {
  background: #33db54;
}
.event-line .bar-step-03 {
  background: #5ee1f9;
}
.event-line .bar-step-04 {
  background: #4753f9;
}
.event-line .bar-step-05 {
  background: #fb384b;
}

/* col */
.calendar-content-wrapper .col-02 {
  width: 410px;
}
.calendar-content-wrapper .col-03 {
  width: 626px;
}
.calendar-content-wrapper .col-04 {
  width: 842px;
}
.calendar-content-wrapper .col-05 {
  width: 1058px;
}
.calendar-content-wrapper .col-06 {
  width: 1274px;
}
.calendar-content-wrapper .col-07 {
  width: 1490px;
}

/**** calendar-popup ****/
.calendar-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background: #f5f5f8;
  z-index: 1000;
  border-radius: 12px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}

/* header */
.calendar-popup .header {
  min-width: 100%;
  height: 52px;
  border-radius: 12px 12px 0 0;
  background: #33297a;
  display: flex;
  align-items: center;
}

.calendar-popup .header .inner {
  width: 100%;
  min-width: auto;
  padding: 0 16px;
  display: flex;
}

.calendar-popup .header .title-wrapper {
  width: 100%;
  margin: 0;
  padding: 0;
}

.calendar-popup .header .title-wrapper .title {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}

.calendar-popup .header .btn-wrapper {
  justify-content: flex-end;
}

.calendar-popup .header .btn-wrapper .btn {
  width: 40px;
  height: 40px;
  background: url(./images/icon_calendar_popup_close.png) no-repeat right center;
  background-size: 16px 16px;
  padding: 0;
}

/* content-container */
.calendar-popup .content-container {
  width: 100%;
  padding: 16px;
}

/* scroll-wrapper */
.calendar-popup .content-container.scroll-wrapper {
  margin: 0 0 16px;
  width: 100%;
  height: 498px;
  overflow: auto;
}

/* scorll style */
.calendar-popup .content-container.scroll-wrapper::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.calendar-popup .content-container.scroll-wrapper::-webkit-scrollbar-thumb {
  height: 70%;
  width: 70%;
  background-color: #b8bac1;
}

.calendar-popup .content-container.scroll-wrapper::-webkit-scrollbar-track {
  background-color: #d6d8df;
}

.calendar-popup .content-container.scroll-wrapper::-webkit-scrollbar-corner {
  background-color: #d6d8df;
}

/* content-wrapper */
.calendar-popup .content-wrapper {
  margin: 0 0 24px 0;
}

/* bottom-btn-wrapper */
.calendar-popup .table-wrapper .bottom-btn-wrapper {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0 0 0;
  padding: 0;
}

.calendar-popup .table-wrapper .bottom-btn-wrapper .btn {
  width: 152px;
  height: 40px;
  margin: 0 0 0 8px;
  padding: 12px 50px;
  border-radius: 8px;
  text-align: center;
  justify-content: center;
}

.calendar-popup .table-wrapper .bottom-btn-wrapper .primary {
  background: #645af1;
  border: none;
  color: #fff;
}

.calendar-popup .table-wrapper .bottom-btn-wrapper .secondary {
  border-radius: 8px;
  border: solid 1px #d4d7f3;
  background-color: #f9f9f9;
}

/* event-info-wrapper */
.calendar-popup .event-info-wrapper {
  margin: 0 0 24px 0;
}

/* event-line */
.calendar-popup .event-line {
  width: 100%;
  height: 40px;
  margin: 0 0 16px;
  display: flex;
  align-items: center;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.07);
  font-size: 14px;
  color: #111;
}

/* detail-info */
.calendar-popup .event-info-detail {
  margin: 0 0 16px;
  padding: 30px 12px 24px;
  background: #fff;
  border: 1px solid #47ddf9;
  position: relative;
}

.calendar-popup .event-info-detail .flag {
  position: absolute;
  top: -5px;
  right: 7px;
  width: 24px;
  height: 32px;
  background: url('./images/icon_calendar_popup_flag.png') no-repeat;
  background-size: 100%;
  display: block;
}

/**** table-info ****/
.calendar-popup .table-info {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0; /* ellipsis */
  table-layout: fixed;
  word-break: break-all;
}

.calendar-popup .table-info tr {
  width: 100%;
  height: 28px;
  padding: 0px 16px;
  vertical-align: top;
  line-height: 140%;
}

.calendar-popup .table-info td {
  text-align: left;
}

.calendar-popup .table-info th {
  padding: 0px 10px 0 0px;
  color: #000;
  font-weight: 600;
  text-align: left;
}

/******************** wrap ********************/
.wrap {
  height: 100%;
  min-width: 100%;
  display: flex;
}

/******************** content ********************/
.content {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  /* width: calc(100vw - 395px); */
  height: 100%;
  padding: 0 0 0 378px !important;
}

/********** location **********/
.location {
  width: 100%;
  margin: 0;
  background: #fff;
  border-radius: 0;
  border-bottom: 1px solid #e1e1e1;
}

.location .inner {
  width: 1542px;
  height: 64px;
  padding: 0 0 0 32px;
  display: flex;
  align-items: center;
}

.location .breadcrumb-wrapper .breadcrumb-item {
  display: flex;
  align-items: center;
  color: #999;
}

.location .breadcrumb-wrapper .breadcrumb-item.active {
  color: #33297a !important;
  font-weight: 600;
  font-size: 14px !important;
  background: none;
}

.location .breadcrumb-wrapper .breadcrumb-item.home {
  padding: 0px 0 3.2px 0;
  color: #33297a !important;
  font-size: 18px;
  font-weight: 600;
}

.location .breadcrumb-wrapper .breadcrumb-item .icon-home {
  margin: 0 8px 0 0;
  width: 24px;
  height: 24px;
  background: url('./images/icon_home.png') no-repeat;
  background-size: 100%;
  display: block;
}

.location .id-wrapper {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.location .id-wrapper .name {
  color: #111;
  font-size: 16px;
  font-weight: 600;
}

.location .id-wrapper .btn-logout {
  margin: 0 0 0 12px;
  height: 20px;
  padding: 0 10px;
  color: #999;
  font-size: 11px;
  border: 1px solid #dedede;
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

/********** page-title **********/
.page-title {
  min-width: 100%;
  height: 78px;
  background: #f5f5fa;
  display: flex;
}

.page-title .inner {
  min-width: 1542px;
  padding: 0 0 0 32px;
  display: flex;
  align-items: center;
}

/* title-wrapper */
.page-title .title-wrapper {
  margin: 0;
  color: #111;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.page-title .title-wrapper i {
  width: 24px;
  height: 24px;
  margin: 0px 12px 0px 0px;
  background-size: 100%;
  display: block;
}

.page-title .title-wrapper .icon-01 {
  background: url('./images/icon_location_title_01.png') no-repeat;
}

.page-title .title-wrapper .icon-02-01 {
  background: url('./images/icon_location_title_01.png') no-repeat;
}

.page-title .title-wrapper .icon-03-01 {
  background: url('./images/icon_location_title_01.png') no-repeat;
}

/* info-wrapper */
.page-title .info-wrapper {
  margin-left: auto;
  color: #000;
  font-weight: 600;
}

.page-title .select-wrapper {
  margin-left: auto;
  color: #000;
  font-weight: 600;
}

/********** content-container **********/
.content-container {
  width: 1542px;
  padding: 32px 0 32px 32px;
}

/********** content-wrapper **********/
.content-wrapper {
  margin: 0 0 48px;
}

.content-wrapper .content-inner {
  margin: 0 0 24px;
}

/* col-wrapper */
.content-wrapper .col-wrapper {
  display: flex;
  justify-content: space-between;
}

.content-wrapper .col-wrapper .col-2 {
  width: 49%;
}

.content-wrapper .col-wrapper .col-3 {
  width: 32%;
}

/********** title-wrapper **********/
.title-wrapper {
  margin: 0 0 20px;
  display: flex;
  align-items: center;
}

.title-wrapper .title {
  color: #111;
  font-size: 20px;
  font-weight: 600;
}

.title-wrapper .right-btn {
  margin-left: auto;
  display: flex;
}

.title-wrapper .right-btn .btn {
  height: 32px;
  margin: 0 0 0 8px;
  padding: 8px 16px;
  background: #fff;
  border: 1px solid #d4d2f1;
  border-radius: 16px;
  color: #111;
  font-size: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.title-wrapper .right-btn .btn.del {
  color: #fb384b;
}

.title-wrapper .right-btn .btn.primary {
  padding: 8px 24px;
  background: #3b50ce;
  border: none;
  color: #fff;
}

.title-wrapper .right-btn .btn.secondary {
  padding: 8px 24px;
  background: #fff;
  border: 1px solid #e1e1e1;
  color: #111;
}

.title-wrapper .right-btn .btn i {
  width: 12px;
  height: 12px;
  margin: 0px 8px 0px 0px;
  display: block;
}

.title-wrapper .right-btn .btn .icon-list {
  background: url('./images/icon_list.png') no-repeat;
}

.title-wrapper .right-btn .btn .icon-edit {
  background: url('./images/icon_edit.png') no-repeat;
}

.title-wrapper .right-btn .btn .icon-add {
  background: url('./images/icon_add.png') no-repeat;
}

.title-wrapper .right-btn .btn .icon-del {
  background: url('./images/icon_del.png') no-repeat;
}

/********** total-search  ***********/
.total-search-container {
  width: 100%;
}

/**** total-search-top ****/
.total-search-top {
  width: 100%;
  height: 56px;
  display: flex;
  border: 1px solid #e1e1e1;
}

/* total-search-th */
.total-search-th {
  width: 15%;
  background-color: #f4f5fb;
  display: flex;
  justify-content: center;
  align-items: center;
}

.total-search-th.left-line {
  border-left: 1px solid #e1e1e1;
}

/* total-search-select */
.total-search-top .total-search-select {
  width: 20%;
  height: 100%;
}

/* total-search-content */
.total-search-content {
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding-right: 24px;
}

/* search-input-wrapper */
.total-search-content .search-input-wrapper {
  height: 100%;
  border-left: 1px solid #e1e1e1;
  display: flex;
  flex-grow: 1;
  align-items: center;
  position: relative;
}

.total-search-content .search-input-wrapper .search-icon {
  position: absolute;
  top: 15px;
  left: 12px;
  width: 24px;
  height: 24px;
  background: url('./images/icon_total_search.png') no-repeat;
  display: block;
}

.total-search-content .search-input-wrapper .btn-close {
  position: absolute;
  top: 17px;
  right: 12px;
  width: 16px;
  height: 16px;
  background: url('./images/icon_search_infput_close.png') no-repeat;
  display: block;
  cursor: pointer;
}

.total-search-content .search-input-wrapper .search-word-input {
  width: 100%;
  height: 100%;
  border: 0;
  padding: 0 40px 0 50px;
}

.total-search-content .search-input-wrapper .search-word-input::placeholder {
  font-size: 14px;
  color: #999;
}

/* search-btn-wrapper  */
.total-search-content .search-btn-wrapper {
  display: flex;
  align-items: center;
}

.total-search-content .search-btn-wrapper .btn-all-search {
  width: 98px;
  height: 40px;
  padding: 12px 23px;
  border-radius: 20px;
  border: solid 1px #d4d7f3;
  background-color: #f7f2ff;
  font-size: 14px;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.total-search-content .search-btn-wrapper .btn-refresh {
  margin: 0 0 0 16px;
  width: 40px;
  height: 40px;
  background: url('./images/refresh_btn.png') no-repeat;
  background-size: 100%;
  display: block;
  cursor: pointer;
}

/* btn-total-search-detail */
.total-search-top .btn-total-search-detail {
  width: 72px;
  font-size: 14px;
  background-color: #f4f5fb;
  border-left: 1px solid #e1e1e1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/**** total-search-row ****/
.total-search-row {
  height: 42px;
  border: 1px solid #e1e1e1;
  border-top: 0;
  display: flex;
}

/* total-search-date-select */
.total-search-date-select {
  width: 35%;
  padding: 0 0 0 24px;
  display: flex;
  align-items: center;
  border-left: 1px solid #e1e1e1;
}

/* total-search-select */
.total-search-row .total-search-select {
  width: 35%;
}

/* form-select-full */
.total-search-container .form-select-full {
  width: 100%;
  height: 100%;
  padding: 0px 24px;
  border: 0;
  border-left: 1px solid #e1e1e1;
  color: #999;
  appearance: none;
  background: url('./images/select_btn.png') no-repeat right 24px center;
}

/********** pagination-container **********/
.pagination-container {
  margin: 32px 0 0;
  display: flex;
  justify-content: center;
}

/********** footer **********/
.footer {
  width: 100%;
  margin: 0px 0 0 0;
  /* border-top:1px solid rgba(255,255,255,0.15); */
  clear: both;
  background: #f4f5fb;
}

.footer .inner {
  width: 1542px;
  padding: 30px 0 18px 32px;
  display: flex;
}

/* company-info-wrapper */
.company-info-wrapper {
  width: 20%;
}

.company-info-wrapper .footer-logo {
  width: 123px;
  height: 36px;
  margin: 0px 0px 32px 0px;
  background: url('./images/footer_logo.png') no-repeat;
  background-size: 100%;
  display: block;
  text-align: left;
}

.company-info-wrapper .company-info {
  padding: 0px 0px 0px;
}

.company-info-wrapper .company-info li {
  margin: 0px 0px 8px 0px;
  font-size: 12px;
  font-weight: 300;
  line-height: 130%;
}

/* menu-wrapper */
.menu-wrapper {
  margin-left: auto;
}

.menu-wrapper .menu {
  margin: 30px 0 78px;
}

.menu-wrapper .menu ul {
  display: flex;
  justify-content: flex-end;
}

.menu-wrapper .menu li {
  height: 10px;
  margin: 0px 0 0 0;
  padding: 0px 20px 0px 20px;
  font-size: 12px;
  font-weight: 300;
  line-height: 10px;
  border-left: 1px solid #33297a;
}

.menu-wrapper .menu li:first-child {
  border-left: none;
}

.menu-wrapper .menu li:last-child {
  padding: 0px 0px 0px 20px;
}

.menu-wrapper .menu li a {
  text-decoration: none;
  color: #33297a;
}
.menu-wrapper .menu li a:link,
a:visited {
  text-decoration: none;
  color: #33297a;
}

.menu-wrapper .menu li a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #33297a;
}

.menu-wrapper .company-info {
}

.menu-wrapper .company-info ul {
  display: flex;
  justify-content: flex-end;
}

.menu-wrapper .company-info li {
  padding: 0px 0px 0px 32px;
  font-size: 12px;
  font-weight: 300;
}

/* copyright-wrapper */
.copyright-wrapper {
  width: 1542px;
  padding: 0px 0px 64px 32px;
}

.copyright {
  padding: 22px 0px 0px 0px;
  border-top: 1px solid #d1d1d1;
  font-size: 12px;
  font-weight: 300;
}

/**************** (PSH) ****************/

/***** status-board-01 ****/
.status-board-01 > tbody > tr > th {
  border: 0;
}

.status-board-01 > tbody > tr > td {
  width: 100%;
  height: 94px;
  font-size: 34px;
  text-align: center;
  border: 0;
}

/**** status-board-02 ****/
.status-board-02 {
  height: 141px;
}

/**** table-season ****/
.table-season {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  text-align: center;
  table-layout: fixed;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
}

.table-season th {
  padding: 7px 0px;
  background: #f4f5fb;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
}

.table-season td {
  padding: 11px 16px;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
}

.table-season .sub_th th {
  padding: 7px 0px;
  background: #f9f9f9 !important;
}

/**** table-graph ****/
.table-graph {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  text-align: center;
  table-layout: fixed;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
}

.table-graph tr {
  border-bottom: 1px solid #e1e1e1;
}

.table-graph td {
  padding: 11px 16px;
  text-align: center;
}

.table-graph th {
  padding: 16px 0px;
  background: #f4f5fb;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  font-size: 14px;
  color: #000;
  font-weight: 600;
}

/* progress-info-wrapper */
.progress-info-wrapper {
}

.progress-info-wrapper li {
  padding: 0 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-info-wrapper li:last-child {
  padding: 0;
}

.progress-info-wrapper li .label {
  width: 40%;
  padding: 0 14px 0 0;
  text-align: right;
}

.progress-info-wrapper li .data {
}

/* graph-bar-wrapper */
.graph-bar-wrapper {
}

.graph-bar-wrapper li {
  padding: 0 0 10px;
  display: flex;
  align-items: center;
}

.graph-bar-wrapper li:last-child {
  padding: 0;
}

.graph-bar-wrapper li .label {
  width: 20%;
  padding: 0 14px 0 0;
  text-align: left;
}

.graph-bar-wrapper li .graph-data {
  width: 20%;
  padding: 0 0px 0 14px;
  text-align: left;
}

/* graph-bar */
.graph-bar-wrapper li .graph-bar {
  width: 100%;
  height: 16px;
  border-radius: 2px;
  background-color: #f1f1fc;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.12);
}

.graph-bar-wrapper li .graph-bar .graph0-blue {
  height: 16px;
  padding: 0;
  text-align: center;
  border-radius: 2px;
  background-image: linear-gradient(to top, #536bef, #7287fb, #9caafc, #7287fb);
}

.graph-bar-wrapper li .graph-bar .graph-blueGreen {
  height: 16px;
  text-align: center;
  border-radius: 2px;
  background-image: linear-gradient(to top, #5595aa, #73c9e4, #96e9fe, #8ddbf3);
}

/* graph-bar-wrapper-L */
.graph-bar-wrapper-L {
}

.graph-bar-wrapper-L li {
  padding: 0 0 28px;
  display: flex;
  align-items: center;
}

.graph-bar-wrapper-L li:last-child {
  padding: 0;
}

.graph-bar-wrapper-L li .label {
  width: 20%;
  padding: 0 14px 0 0;
  text-align: left;
  font-size: 14px;
  color: #111;
}

.graph-bar-wrapper-L li .graph-data {
  width: 30%;
  padding: 0 0px 0 14px;
  text-align: left;
  font-size: 34px;
  font-weight: 300;
}

/* graph-bar */
.graph-bar-wrapper-L li .graph-bar {
  width: 100%;
  height: 24px;
  border-radius: 4px;
  background-color: #f1f1fc;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.12);
}

.graph-bar-wrapper-L li .graph-bar .graph0-blue {
  height: 24px;
  padding: 0;
  text-align: center;
  border-radius: 4px;
  background-image: linear-gradient(to top, #536bef, #7287fb, #9caafc, #7287fb);
}

.graph-bar-wrapper-L li .graph-bar .graph-blueGreen {
  height: 24px;
  text-align: center;
  border-radius: 4px;
  background-image: linear-gradient(to top, #5595aa, #73c9e4, #96e9fe, #8ddbf3);
}

/**** table-season ****/
.table-season {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  text-align: center;
  table-layout: fixed;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
}
.table-season th {
  padding: 7px 0px;
  background: #f4f5fb;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
}

.table-season td {
  padding: 11px 16px;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
}
.table-season .sub_th th {
  padding: 7px 0px;
  background: #f9f9f9 !important;
}

/**** list-tabs ****/
.list-tabs-header {
}

.list-tabs-header .tab {
  width: 45%;
  height: auto;
}
.list-tabs-header .tab > ul {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
}
.list-tabs-header .tab > ul > li {
  width: 237px;
  height: 56px;
  align-items: center;
}
.list-tabs-header .tab > ul > li > a {
  width: 237px;
  height: 56px;
  display: inline-block;
  padding-left: 24px;
  text-align: left;
  font-size: 18px;
  color: #999999;
  line-height: 50px;
}
.list-tabs-header .tab > ul > li > a:hover,
.active {
  color: #111;
  font-size: 18px;
  font-weight: 600;
  display: inline-block;
  background: url('./images/list_tabs_bg.png') no-repeat;
}

.list-tabs-body {
}

/**************** (PSH) ****************/

/******************** common ********************/

/********** table **********/
.table-wrapper {
}

.table-wrapper .info-wrapper {
  width: 100%;
  padding: 0 0 20px;
  display: flex;
  align-items: center;
}

.table-wrapper .btn-wrapper {
  padding: 0 0px 0 0px;
}

.table-wrapper .info-wrapper .btn {
  height: 32px;
  padding: 0px 20px;
  background-color: #f7f2ff;
  border: solid 1px #d4d7f3;
  border-radius: 4px;
  font-size: 14px;
  color: #33297a;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.table-wrapper .info-wrapper .total {
  padding: 0 20px 0 0px;
}

.table-wrapper .info-wrapper .total .bold {
  font-size: 16px;
  font-weight: 600;
}

.table-wrapper .info-wrapper .select-wrapper {
  margin-left: auto;
}

/* date-select-wrapper */
.date-select-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.date-select-wrapper .date {
  margin: 0px 0px 0px 0px;
}

.date-select-wrapper .to {
  margin: 0px 20px 0px 20px;
}

.date-select-wrapper .select-wrapper {
  padding: 0 0 0 16px;
}

.date-select-wrapper .btn-celendar-select {
  width: 16px;
  height: 16px;
  margin: 0px 8px 2px 0px;
  background: url('./images/icon_celendar_select.png') no-repeat;
  background-size: 100%;
  display: block;
  cursor: pointer;
}

/* bottom-toggle-btn-wrapper  */
.bottom-toggle-btn-wrapper {
  width: 100%;
  height: 40px;
  padding: 0 0 0px;
  background: #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottom-toggle-btn-wrapper .toggle-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.bottom-toggle-btn-wrapper .toggle-btn i {
  width: 12px;
  height: 7px;
  margin: 0px 0px 0px 10px;
  display: block;
}

.bottom-toggle-btn-wrapper .toggle-btn .arrow-down {
  background: url('./images/icon_bottom-toggle_arrow_down.png') no-repeat;
}

/**** table-basic ****/
.table-basic {
  width: 100%;
  border: 1px solid #e1e1e1;
  border-collapse: collapse;
  border-spacing: 0; /* ellipsis */
  table-layout: fixed;
  word-break: break-all;
}

.table-basic tr {
  border-bottom: 1px solid #e1e1e1;
}

.table-basic td {
  padding: 11px 16px;
  border-right: 1px solid #e1e1e1;
}

.table-basic th {
  padding: 11px 16px;
  background: #f4f5fb;
  border-right: 1px solid #e1e1e1;
  color: #000;
  font-weight: 600;
  text-align: center;
}

/**** table-list ****/
.table-list {
  min-width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  text-align: center;
  table-layout: fixed;
  border: 1px solid #e1e1e1;
}

.table-list tr {
  border-bottom: 1px solid #e1e1e1;
}

.table-list td {
  padding: 11px 16px;
  text-align: center;
  border-left: 1px solid #e1e1e1;
}

.table-list th {
  padding: 16px 0px;
  background: #f4f5fb;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  font-size: 14px;
  color: #000;
  font-weight: 600;
}

.table-list .btn {
  height: 32px;
  padding: 0px 20px;
  background-color: #f9f9f9;
  border: solid 1px #d4d7f3;
  border-radius: 4px;
  font-size: 14px;
  color: #111;
  white-space: nowrap;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  cursor: pointer;
}

.table-list .inner {
  display: flex;
  align-items: center;
}

.table-list .label {
  margin: 0 14px 0 0;
}

/********** table-scorll-wrapper **********/
.table-scorll-wrapper {
  border: 1px solid #e1e1e1;
}

/* scorll */
.table-scorll-wrapper.scorll-y {
  overflow-y: auto;
  border-right: 1px solid #e1e1e1;
}

.table-scorll-wrapper.scorll-x {
  overflow-x: scroll;
  border-right: 1px solid #e1e1e1;
}

.table-scorll-wrapper.scorll-all {
  overflow: scroll;
  border-right: 1px solid #e1e1e1;
}

/* scorll style */
.table-scorll-wrapper::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

.table-scorll-wrapper::-webkit-scrollbar-thumb {
  height: 70%;
  width: 70%;
  background-color: #b8bac1;
}

.table-scorll-wrapper::-webkit-scrollbar-track {
  background-color: #d6d8df;
}

.table-scorll-wrapper::-webkit-scrollbar-corner {
  background-color: #d6d8df;
}

/* table-list */
.table-scorll-wrapper .table-list {
  border: 0 !important;
}

.table-scorll-wrapper .table-list th {
  border: 0 !important;
}

.table-scorll-wrapper .table-list td:first-child {
  border-left: 0 !important;
}

/**** table-scroll-list-wrapper ****/
.table-scroll-list-wrapper {
  width: 100%;
  border: 1px solid #e1e1e1;
  position: relative;
}

.table-scroll-list-wrapper .title-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 56px;
  background: #f4f5fb;
  border-bottom: 1px solid #eceffc;
  display: block;
}

.table-scroll-list-wrapper .inner-wrapper {
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  display: block;
}

.table-scroll-list-wrapper .table-list {
  width: 100%;
  margin-top: 56px;
  border-collapse: collapse;
  border-spacing: 0;
  text-align: center; /* ellipsis */
  table-layout: fixed;
  border: 0;
}
.table-scroll-list-wrapper .table-list .th-name {
  position: absolute;
  top: 0;
  width: inherit;
  height: 56px;
  line-height: 56px;
  border-left: 1px solid #eceffc;
  display: block;
}

.table-scroll-list-wrapper .table-list tr {
  border-bottom: 1px solid #eceffc;
}

.table-scroll-list-wrapper .table-list tr:last-child {
  border-bottom: none !important;
}

.table-scroll-list-wrapper .table-list td {
  padding: 11px 16px;
  text-align: center;
}

.table-scroll-list-wrapper .table-list td + td {
  border-left: 1px solid #eceffc;
}

.table-scroll-list-wrapper .table-list td:first-child {
  border-left: none;
}

.table-scroll-list-wrapper .table-list th {
  padding: 0px;
  text-align: inherit;
  border: none;
}

.table-scroll-list-wrapper .table-list th .th-name {
  border-left: none;
}

/* scorll style */
.table-scroll-list-wrapper .inner-wrapper::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

.table-scroll-list-wrapper .inner-wrapper::-webkit-scrollbar-thumb {
  height: 70%;
  width: 70%;
  background-color: #b8bac1;
}

.table-scroll-list-wrapper .inner-wrapper::-webkit-scrollbar-track {
  background-color: #d6d8df;
}

.table-scroll-list-wrapper .inner-wrapper::-webkit-scrollbar-corner {
  background-color: #d6d8df;
}

/********** input **********/
.form-control {
  display: block;
  width: 100%;
  padding: 0.571rem 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.45;
  color: #6e6b7b;
  background-color: #fff;
  background-clip: padding-box;
  border-bottom: 1px solid #ccc !important;
  border: none;
  appearance: none;
  border-radius: 0;
  /* transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;  */
}

.date-picker {
  padding: 0 0 0 36px;
  background: url(./images/icon_celendar_select.png) no-repeat left 10px center;
  background-size: 16px;
  border-bottom: none !important;
}

/********** select  **********/
.select-wrapper {
  margin: 0;
}

.select-wrapper .form-select {
  width: auto;
  height: 32px;
  padding: 0px 40px 0px 10px;
  -moz-padding-start: calc(1rem - 3px);
  background: #fff url('./images/icon_select_arrow.png') no-repeat;
  background-position: right -1px center;
  background-size: 32px 32px;
  border: 1px solid #d4d7f3;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.45;
  color: #444;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

/* form-select-full */
.form-select-full {
  width: 100%;
  height: 100%;
  padding: 0px 0px 0 0;
  border: 0;
  border: none;
  color: #444;
  appearance: none;
  background: url('./images/select_btn.png') no-repeat right center;
}

/********** btn  **********/
.btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-wrapper.align-left {
  justify-content: start;
}

.btn-wrapper.align-right {
  justify-content: end;
}

/* btn-wrapper btn */
.btn-wrapper .btn {
  background: #fff;
  color: #111;
  display: flex;
  cursor: pointer;
}

/* btn */
.btn {
  background: #fff;
  color: #111;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  cursor: pointer;
}

/* style */
.btn.primary {
  background: #3b50ce;
  border: none;
  color: #fff;
}

.btn.secondary {
  background: #fff;
  border: 1px solid #e1e1e1;
  color: #111;
}

/* size */
.btn.L {
  height: 44px;
  margin: 0 4px;
  padding: 0px 48px 0;
  border-radius: 44px;
  font-size: 16px;
  font-weight: 600;
}

.btn.M {
  height: 40px;
  margin: 0 2px;
  padding: 0px 24px;
  border-radius: 40px;
  font-size: 14px;
  font-weight: 600;
}

.btn.S {
  height: 32px;
  margin: 0 2px;
  padding: 0px 16px;
  border-radius: 32px;
  font-size: 14px;
  font-weight: 600;
}

/* btn icon */
.btn .file {
  margin: 0 6px 0 0;
  width: 12px;
  height: 12px;
  background-size: 100%;
  background: url(./images/icon_file.png) no-repeat;
}

/********** color **********/
.bg-wihte {
  background: #fff !important;
}

.bg-darkGray {
  background: #e1e1e1 !important;
}

.text-color-green {
  color: #0fb746;
}

.text-color-red {
  color: #fb384b;
}

.text-color-blue {
  color: #465ee4;
}

.text-color-blueGreen {
  color: #30a4c5;
}

.text-color-black {
  color: #111;
}
