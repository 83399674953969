@charset "utf-8";

/* Basic  */
@import url('./components.style.css');
@import url('./bootstrap.style.css');
@import url('./bootstrapExtended.style.css');
@import url('./web.style.css');

/* default */
html {
  height: 100%;
  background: #fff;
}

div,
p,
ul,
li,
table,
th,
td,
textarea,
input,
form,
h1,
h2,
h3,
h4,
h5,
h6,
dt,
dl,
dd,
em,
address,
cite,
ul,
ol,
dl,
dd,
dt,
li {
  list-style-type: none;
  margin: 0;
  padding: 0px;
  box-sizing: border-box;
}

body {
  width: 100%;
  height: 100vh !important;
  background: cover;
  margin: 0px;
  padding: 0px;
  font-family: 'Pretendard', sans-serif !important;
  color: #444;
  font-weight: normal;
  font-size: 14px;
  overflow-y: scroll;
  background: #fff;
}

button {
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
}

/* text align */
.align-left {
  text-align: left !important;
}

.align-center {
  text-align: center !important;
}
.align-right {
  text-align: right !important;
}

/* margin */
.mb-20 {
  margin: 0 0 24px;
}

/* link */
/* a {
    text-decoration:none;
    color:#777;
}

a:link, a:visited {
    text-decoration:none;
    color:#777;
}

a:hover, a:focus, a:active {
    text-decoration:none;
    color: rgba(255,255,255,0.5);
} */
