/* 버튼 이벤트 */
.button-event {
  cursor: pointer !important;
  user-select: none !important;
}
.button-event:hover {
  opacity: 0.8 !important;
}
.button-event:active {
  transform: translateY(1px) !important;
}

/* 텍스트 그림자 */
.text-shadow {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
}
.text-shadow-xs {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}

/* **************************** */

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}
.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

/* **************************** */

/**
 * 퍼블리셔 CSS 수정
 */

.main-menu {
  position: relative !important;
  z-index: 1 !important;
}

.main-menu-bg-color {
  background: #343861;
}

.footer .inner {
  width: auto !important;
  padding: 30px 30px 18px 32px !important;
  display: flex;
}

.copyright-wrapper {
  width: auto !important;
  padding: 0 30px 64px 32px !important;
}

.location .inner {
  width: auto !important;
  height: 64px;
  padding: 0 30px 0 32px !important;
  display: flex;
  align-items: center;
}

.page-title .inner {
  min-width: 100% !important;
  /*width: auto !important;*/
  padding: 0 30px 0 32px !important;
  display: flex;
  align-items: center;
}

.date-picker {
  padding: 0 0 0 0 !important;
  background: none !important;
  background-size: 16px;
  border-bottom: none !important;
}

.btn {
  margin: 0 !important;
}

.date-select-wrapper {
  display: flex;
  justify-content: start !important;
  align-items: center;
}

.content-wrapper {
  margin: 0 !important;
}

.form-control {
  width: unset !important;
}

.title-wrapper {
  margin: 0 0 7px !important;
  display: flex;
  align-items: center;
}

/* **************************** */

/* 퍼블리셔 버튼 */
.pb-button {
  background: #fff;
  border: 1px solid #d4d2f1;
  border-radius: 16px;
  color: #111;
  font-size: 15px;
  display: flex;
  align-items: center;
  font-weight: 600;
  height: 35px;
  padding: 8px 24px;
  cursor: pointer;
  user-select: none;
}
.pb-button:hover {
  opacity: 0.8;
}
.pb-button:active {
  transform: translateY(1px);
}
.pb-button.del {
  color: #fb384b;
}
.pb-button.primary {
  padding: 8px 24px;
  background: #645af1;
  border: none;
  color: #fff;
}
.pb-button.secondary {
  padding: 8px 24px;
  background: #f9f9f9;
  border: 1px solid #e1e1e1;
  color: #111;
}
.pb-button i {
  width: 12px;
  height: 12px;
  margin: 0 8px 0 0;
  display: block;
}
.pb-button .icon-list {
  background: url('./images/icon_list.png') no-repeat;
}
.pb-button .icon-edit {
  background: url('./images/icon_edit.png') no-repeat;
}
.pb-button .icon-add {
  background: url('./images/icon_add.png') no-repeat;
}
.pb-button .icon-del {
  background: url('./images/icon_del.png') no-repeat;
}

/* 날짜 선택 피커 */
.date-picker:focus {
  outline: none;
}
.date-picker::-webkit-calendar-picker-indicator {
  cursor: pointer;
}
.date-picker::-webkit-calendar-picker-indicator:hover {
  opacity: 0.8;
}
.date-picker::-webkit-calendar-picker-indicator:active {
  transform: translateY(1px);
}

/* 폼 그리드 */
.form-grid {
  border: 1px solid #e1e1e1;
  background-color: #e1e1e1;
}

/* 폼 그리드 항목 레이블 */
.form-grid-col-label {
  width: 180px;
  padding: 11px 16px;
  background: #f4f5fb;
  border-right: 1px solid #e1e1e1;
  color: #000;
  font-weight: 600;
  text-align: center;
}

/* 폼 그리드 항목 값 */
.form-grid-col-value {
  padding: 11px 16px;
  text-align: left;
  background-color: white;
}

/* 폼 가로 크기 */
.pb-form-size-tiny {
  width: 50px !important;
}
.pb-form-size-mini {
  width: 100px !important;
}
.pb-form-size-xs {
  width: 150px !important;
}
.pb-form-size-sm {
  width: 200px !important;
}
.pb-form-size-md {
  width: 300px !important;
}
.pb-form-size-lg {
  width: 400px !important;
}
.pb-form-size-xl {
  width: 500px !important;
}
.pb-form-size-half {
  width: 50% !important;
}
.pb-form-size-full {
  width: 100% !important;
}

/* 컬럼 */
.column-box {
  border: 1px solid #e1e1e1;
}
.column-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0;
  background-color: #f4f5fb;
  border-bottom: 1px solid #e1e1e1;
}
.column-header span {
  font-size: 1rem;
  font-weight: 600;
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}
.column-content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 11px 0;
  background-color: #ffffff;
}
.column-content span {
  font-size: 1rem;
  font-weight: 600;
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.div-bg-color {
  background-color: #f4f5fb;
}

.div-menu-bg-color {
  background-color: #343861;
}

/* 컴포넌트 모달 버튼 영역 */
.component-modal-button-area {
  position: sticky !important;
  left: 0 !important;
  bottom: 0 !important;
  width: 100%;
  padding: 1.2rem 0rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: rgba(255, 255, 255, 1);
  /*border-top-width: 1px;*/
  /*border-color: rgb(243, 244, 246);*/
  /*backdrop-filter: blur(4px);*/
  z-index: 20 !important;
}
.component-modal-button-area > div {
  margin-left: 0.5rem;
}

/* 컴포넌트 모달 버튼 영역 */
.component-modal-button-top-area {
  /*position: absolute !important;*/
  /*left: 0 !important;*/
  /*bottom: 0 !important;*/
  width: 100%;
  /*padding-top: 1rem;*/
  padding-bottom: 1rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: rgb(255, 255, 255);
  /*border-top-width: 1px;*/
  /*border-bottom-width: 1px;*/
  /*border-color: rgb(243, 244, 246);*/
  /*z-index: 20 !important;*/
}
.component-modal-button-top-area > div {
  margin-left: 0.5rem;
}

.component-modal {
  /*padding-bottom: 5rem;*/
}

/* **************************** */

/**
 * Mantine
 */

.mantine-MonthsList-monthsList tr {
  border-bottom: 0 !important;
}
.mantine-MonthsList-monthsList td {
  border-right: 0 !important;
}

.mantine-Modal-header {
  z-index: 20 !important;
}

.mantine-Modal-body {
  position: relative;
  z-index: 10 !important;
}

/* **************************** */

/**
 * AG-Grid
 */

.ag-theme-alpine {
  --ag-border-color: #e1e1e1 !important;
  --ag-header-background-color: #f4f5fb !important;
}

/* **************************** */

/**
 * CKEditor
 */

/*.ck.ck-editor {*/
/*  width: 100%;*/
/*}*/

/* **************************** */

@keyframes ani-object-appear {
  0% {
    transform: scale(0.9) translateZ(0);
    opacity: 0;
  }
  100% {
    transform: scale(1) translateZ(0);
    opacity: 100;
  }
}
.object-appear {
  animation: ani-object-appear 0.3s cubic-bezier(0.34, 1.56, 0.64, 1) forwards;
}

@keyframes ani-object-left-appear {
  0% {
    transform: translateX(-100px) translateZ(0);
    opacity: 0;
  }
  100% {
    transform: translateX(0) translateZ(0);
    opacity: 100;
  }
}
.object-left-appear {
  animation: ani-object-left-appear 0.3s cubic-bezier(0.16, 1, 0.3, 1) forwards;
}

@keyframes ani-object-opacity-appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100;
  }
}
.object-opacity-appear {
  animation: ani-object-opacity-appear 0.3s cubic-bezier(0.34, 1.56, 0.64, 1)
    forwards;
}

/* **************************** */

.left-menu {
}

.left-menu .icon {
  width: 24px;
  height: 24px;
  background-size: 100%;
  display: block;
}

.left-menu .icon.icon-01 {
  background: url('./images/main_menu_icon_01.png') no-repeat;
}

.left-menu .icon.icon-02 {
  background: url('./images/main_menu_icon_02.png') no-repeat;
}

.left-menu .icon.icon-03 {
  background: url('./images/main_menu_icon_03.png') no-repeat;
}

.left-menu .icon.icon-04 {
  background: url('./images/main_menu_icon_04.png') no-repeat;
}

.left-menu .icon.icon-05 {
  background: url('./images/main_menu_icon_05.png') no-repeat;
}

.left-menu .icon.icon-06 {
  background: url('./images/main_menu_icon_06.png') no-repeat;
}

.left-menu .icon.icon-07 {
  background: url('./images/main_menu_icon_07.png') no-repeat;
}

/* **************************** */

/**
 * react-accessible-treeview
 */

.directory {
  background: #242322;
  font-family: monospace;
  font-size: 16px;
  color: white;
  user-select: none;
  padding: 20px;
  border-radius: 0.4em;
}

.directory .tree,
.directory .tree-node,
.directory .tree-node-group {
  list-style: none;
  margin: 0;
  padding: 0;
}

.directory .tree-branch-wrapper,
.directory .tree-node__leaf {
  outline: none;
  outline: none;
}

.directory .tree-node {
  cursor: pointer;
}

.directory .tree-node:hover {
  background: rgba(255, 255, 255, 0.1);
}

.directory .tree .tree-node--focused {
  background: rgba(255, 255, 255, 0.2);
}

.directory .tree .tree-node--selected {
  background: rgba(48, 107, 176);
}

.directory .tree-node__branch {
  display: block;
}

.directory .icon {
  vertical-align: middle;
  padding-right: 5px;
}

/* tippy.js */
.tippy-box[data-theme~='yellow'] {
  background-color: #fffadf;
  color: black;
}

.fc-event-title.fc-sticky {
  overflow: hidden;
  text-overflow: ellipsis;
}

.mantine-Modal-title {
  width: 100% !important;
}

.ag-grid-selected-row {
  background-color: rgb(2 132 199) !important;
  color: white !important;
}

.ag-grid-none-selected-row {
  background-color: rgb(255 255 255) !important;
}

.ag-grid-multiple-header-name .ag-header-cell-label .ag-header-cell-text {
  white-space: pre-wrap !important;
}

.ag-grid-cell-vertical-border .ag-center-cols-clipper .ag-cell-value {
  border-left: 1px solid rgb(229 231 235) !important;
}

.mantine-WeekdaysRow-weekday.mantine-Calendar-weekday {
  text-align: center;
}

.ag-grid-hide-header .ag-header {
  display: none !important;
}
